const BASE_URL = process.env.REACT_APP_BASE_URL;

const endpoint = {
  flags: "api/v1/flags",
  adLogin: "api/v1/adLogin",
  refreshToken: "api/v1/refreshToken",
};

const FUNCTION_URL = process.env.REACT_APP_AD_LOGIN_URL;

export const serviceList = (serviceName) => BASE_URL + endpoint[serviceName];
export const deleteReleaseFlag = (serviceName, id) =>
  BASE_URL + endpoint[serviceName] + "/" + id;

export const login = (serviceName) => FUNCTION_URL + endpoint[serviceName];
