import React, { useMemo, useState } from "react";
import Select from "react-select";
import validation from "./validator/validator";
import IconGenerator from "../constants/IconPack/iconGenerator";

const SelectField = (props) => {
  const {
    options,
    placeholder,
    handleSelect,
    recievedValues,
    disabled,
    classNamePrefix,
    className,
    property,
  } = props;

  const [validate, setValidation] = useState({});

  const createOption = (label) => ({
    label: label.toUpperCase(),
    value: label,
  });

  const values = useMemo(() => {
    if (recievedValues?.value) {
      return createOption(recievedValues.value);
    }
    return { value: "", label: "" };
  }, [recievedValues]);

  const handleOnChange = (event) => {
    let inputValue = "";
    let validatedResult;
    let inputToValidate = { ...recievedValues };

    inputValue = event?.value || "";

    if (
      inputToValidate.validations &&
      inputToValidate.validations?.length > 0
    ) {
      validatedResult = validation(inputToValidate, inputValue);
      setValidation(validatedResult);
    }

    inputToValidate.value = inputValue;
    inputToValidate.isInputValid = validatedResult.isInputValid;

    handleSelect(inputToValidate);
  };
  const getOptionLabel = (option) => (
    <div className="selectOption">
      {option["value"] ? (
        <IconGenerator
          classes="icon-svg icon-theme icon-size-10 icon-in-dropdown"
          iconName={`icon-${option["value"]}`}
        />
      ) : (
        ""
      )}
      <span>{option.label}</span>
    </div>
  );

  return (
    <div>
      <Select
        classNamePrefix={classNamePrefix}
        className={className}
        options={options}
        onChange={(event) => handleOnChange(event)}
        placeholder={placeholder}
        isClearable={true}
        isSearchable={false}
        value={values}
        isDisabled={disabled}
        getOptionLabel={(options) => getOptionLabel(options)}
      ></Select>
      {!validate?.isInputValid && validate.errors && (
        <span className="validation">{validate?.errors}</span>
      )}
    </div>
  );
};

export default SelectField;
