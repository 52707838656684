import React, { useEffect, useMemo, useState } from "react";
import validation from "./validator/validator";

const InputField = (props) => {
  const { placeholder, handleInput, type, recievedValues, disabled } = props;
  const [validate, setValidation] = useState({});

  const values = useMemo(() => {
    if (recievedValues?.value) return recievedValues.value;
    return "";
  }, [recievedValues]);

  const handleOnInput = (value) => {
    const inputToValidate = { ...recievedValues };
    let validatedResult;

    if (
      inputToValidate.validations &&
      inputToValidate.validations?.length > 0
    ) {
      validatedResult = validation(inputToValidate, value);
      setValidation(validatedResult);
    }

    inputToValidate.value = value;
    inputToValidate.isInputValid = validatedResult.isInputValid;

    handleInput(inputToValidate);
  };

  return (
    <div>
      <input
        onInput={(event) => {
          handleOnInput(event.target.value);
        }}
        type={type}
        placeholder={placeholder}
        className={`form-control`}
        value={values}
        disabled={disabled}
      />
      {!validate?.isInputValid && validate.errors && (
        <span className="validation">{validate?.errors}</span>
      )}
    </div>
  );
};

InputField.defaultProps = {
  type: "text",
};

export default InputField;
