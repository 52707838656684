import { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import Routes from "./route";
import { loginRequest, msalInstance } from "../src/constants/config/msalConfig";
import "@progress/kendo-theme-bootstrap/dist/all.css";
import {
  getItemFromSessionStorage,
  getToken,
} from "./pages/loginSettings/login";
import IconPack from "./constants/IconPack";
import "./designSystem/pages/_header.scss";
import logo from "./assets/blackVeatchLaminarLogo.png";

function App() {
  const [isLoggedIn, setLoggedIn] = useState(false);
  const [showUserProfile, setShowUserProfile] = useState(false);
  const [accountName, setAccountData] = useState({ name: "", email: "" });
  const [accountInitials, setAccountInitials] = useState("");
  const authCallback = (error, response) => {
    getToken().then((res) => {
      let loginData = JSON.parse(getItemFromSessionStorage("loginData"));
      if (loginData && loginData.user) {
        setAccountData({
          name:
            loginData.user?.userFirstName + " " + loginData.user?.userLastName,
          email: loginData.user?.userEmail,
        });
        setAccountInitials(
          loginData.user?.userFirstName?.[0] + loginData.user?.userLastName?.[0]
        );
      }
      setLoggedIn(res);
    });
  };

  useEffect(() => {
    const account = msalInstance.getAccount();
    console.log("account:- ", account);
    msalInstance.handleRedirectCallback(authCallback);
    if (!account) {
      msalInstance.loginRedirect(loginRequest);
    } else {
      let loginData = JSON.parse(getItemFromSessionStorage("loginData"));
      if (loginData && loginData.user?.userEmail === account.userName) {
        setAccountData({
          name:
            loginData.user?.userFirstName + " " + loginData.user?.userLastName,
          email: loginData.user?.userEmail,
        });

        setAccountInitials(
          loginData.user?.userFirstName?.[0] + loginData.user?.userLastName?.[0]
        );
        setLoggedIn(true);
      }
    }
  }, []);

  const handleClickUserProfile = () => {
    setShowUserProfile(!showUserProfile);
  };

  return (
    <>
      <div className="body-wrapper">
        <div className="engig-header-wrapper">
          <div className="bv-container">
            <div className="engig-header-section">
              <div className="engig-header__left">
                <img src={logo} className="company-logo" alt="company logos" />
              </div>
              <div className="engig-header__right">
                {/* <button className="icon-btn icon-btn--primary">
                  <IconGenerator
                    classes="icon-svg icon-theme icon-size-16"
                    iconName="icon-help"
                  />
                </button>
                <button className="icon-btn icon-btn--primary new-notification">
                  <IconGenerator
                    classes="icon-svg icon-theme icon-size-16"
                    iconName="icon-bell"
                  />
                </button> */}
                {isLoggedIn ? (
                  <button
                    className="icon-btn dropdownProfile"
                    onClick={handleClickUserProfile}
                    onBlur={() => setShowUserProfile(false)}
                  >
                    <span className="name-initials">{accountInitials}</span>
                    {showUserProfile && (
                      <>
                        <div className="dropdown-userProfile-container">
                          <div className="user-initials-block">
                            <div className="user-initials">
                              {accountInitials}
                            </div>
                          </div>
                          <div className="user-name">
                            {accountName && accountName.name}
                          </div>
                          <div className="user-email">
                            {" "}
                            {accountName && accountName.email}
                          </div>
                          {/* <div className="line"></div>
                          <div>
                            <button type="button" className="user-sign-out">
                              Sign Out
                            </button>
                          </div> */}
                        </div>
                      </>
                    )}
                  </button>
                ) : null}
              </div>
            </div>
          </div>
        </div>
        {isLoggedIn ? (
          <>
            <div className="bv-container">
              <div className="router-section">
                <Routes />
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="global-loader">
              <Spinner animation="border" role="status" variant="primary">
                <span className="visually-hidden">Loading...</span>
              </Spinner>{" "}
            </div>
            <div className="redirecting">Redirecting...</div>
          </>
        )}
        <IconPack />
      </div>
    </>
  );
}

export default App;
