import React from 'react';
const IconGenerator = (props) => {
  return (
    <svg
      className={props.classes}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      {props.iconTitle ? <title>{props.iconTitle} </title> : ''}
      <use xlinkHref={'#' + props.iconName}></use>
    </svg>
  );
};
export default IconGenerator;
