const env = [
  { environment: "dev" },
  { environment: "sit" },
  { environment: "sit" },
  { environment: "uat" },
  { environment: "uat" },
  { environment: "prod" },
];

const releaseStatus = [
  { status: "true" },
  { status: "false" },
  { status: "evaluating" },
];

const envDropDownValues = [
  { value: "dev", label: "DEV",propertyIcon:'dev' },
  { value: "sit", label: "SIT",propertyIcon:'sit' },
  { value: "uat", label: "UAT",propertyIcon:'uat' },
  { value: "prod", label: "PROD",propertyIcon:'prod' },
];

const statusDropDownValues = [
  { value: "true", label: "TRUE" },
  { value: "false", label: "FALSE" },
  { value: "evaluating", label: "EVALUATING" },
];
const flagType = [{ flagType: "feature-flag" }, { flagType: "release-flag" }];

export {
  env,
  releaseStatus,
  flagType,
  envDropDownValues,
  statusDropDownValues,
};
