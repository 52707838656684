import { Input } from "@progress/kendo-react-inputs";

const FeatureNameSearch = (props) => {
  return (
    <Input
      label="Search"
      data-testId="feature-search"
      value={props.firstFilterProps.value}
      onChange={(event) => {
        const value = event.target.value === "null" ? null : event.target.value;
        const { firstFilterProps } = props;
        firstFilterProps.onChange({
          value,
          operator: "eq",
          syntheticEvent: event.syntheticEvent,
        });
      }}
    />
  );
};

export default FeatureNameSearch;
