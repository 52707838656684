const validation = (input, inputValue) => {
  const validations = input.validations;
  let errors;
  let validatedResult = {};

  for (const index in validations) {
    if (validations[index] === "required") {
      if (inputValue === "" || typeof inputValue === undefined) {
        errors = input.name + " is required";
        return { isInputValid: false, errors };
      } else {
        validatedResult = { isInputValid: true };
      }
    }

    if (validations[index] === "feature-name-syntax") {
      const regex = /^FF_([0-9a-zA-z]+)_([0-9a-zA-z]+)/;
      if (!regex.test(inputValue)) {
        errors =
          input.name +
          ' should be of format \n "FF_<feature_name>_<sub_feature_name>"';
        return { isInputValid: false, errors };
      } else {
        validatedResult = { isInputValid: true };
      }
    }
  }
  return validatedResult;
};

export default validation;
