import React, { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import InputField from "../../sharedComponents/inputField";
import SelectField from "../../sharedComponents/selectField";
import MultiValueInputField from "../../sharedComponents/multiValueInputField";
import Alerts from "../../sharedComponents/alertPopUps";
import { Button, Spinner } from "react-bootstrap";
import { serviceList } from "../../services/serviceList";
import { AxiosInstance as axios } from "../../services/interceptor";
import { Tooltip } from "@progress/kendo-react-tooltip";
import IconGenerator from "../../constants/IconPack/iconGenerator";
import "./featureFlagStyle.scss";
import {
  envDropDownValues,
  statusDropDownValues,
} from "../../constants/featureAndReleaseFlag";
import { useNavigate } from "react-router-dom";

function FeatureFlagRelease(props) {
  let navigate = useNavigate();

  const location = useLocation();
  const [environment, setEnvironment] = useState({
    name: "Environment",
    validations: ["required"],
  });
  const [status, setReleaseStatus] = useState({
    name: "Release Status",
    validations: ["required"],
  });
  const [featureName, setFeatureName] = useState({
    name: "Feature",
    validations: ["required", "feature-name-syntax"],
  });

  const [configurationList, setConfigurationList] = useState([
    { users: [], projects: [] },
  ]);

  const [isInputValid, setInputValid] = useState(true);
  const [showAlert, setAlert] = useState(false);
  const [showLoader, setLoader] = useState(false);
  const [alertSpec, setAlertSpec] = useState({});

  useEffect(() => {
    setLoader(true);
    const { state } = location;
    const params = {
      environment: state?.environment,
      feature: state?.feature,
      page: 1,
      limit: 1,
    };

    handleGetRequest(params);
  }, []);

  const timeOut = () => {
    setTimeout(() => setAlert(false), 5000);
  };

  const handleGetRequest = async (params) => {
    try {
      const response = await axios.get(serviceList("flags"), {
        params,
      });
      const responseData = await response?.data?.data[0];
      setEnvironment({
        ...environment,
        value: responseData.environment,
        isInputValid: true,
      });
      setFeatureName({
        ...featureName,
        value: responseData.feature,
        isInputValid: true,
      });
      setReleaseStatus({
        ...status,
        value: responseData.status,
        isInputValid: true,
      });

      if (responseData.evaluationCriteria)
        setConfigurationList(responseData.evaluationCriteria);
      setLoader(false);
    } catch (err) {
      setLoader(false);
    }
  };

  useEffect(() => {
    if (
      environment?.isInputValid &&
      featureName?.isInputValid &&
      status?.isInputValid
    ) {
      setInputValid(true);
    } else {
      setInputValid(false);
    }
  }, [environment, featureName, status]);

  useMemo(() => {
    if (status?.value === "true" || status?.value === "false") {
      setConfigurationList([{ users: [], projects: [] }]);
    }
  }, [status]);

  const handleSave = (event) => {
    setLoader(true);
    let flagType = "";
    if (/^FF/.test(featureName.value)) {
      flagType = "feature-flag";
    } else if (/^RF/.test(featureName.value)) {
      flagType = "release-flag";
    }

    let payload = {
      environment: environment.value,
      status: status.value,
      feature: featureName.value,
      flagType,
    };
    let evaluationCriteria = {};
    if (status.value === "evaluating") {
      evaluationCriteria = configurationList;
      payload = { ...payload, evaluationCriteria: evaluationCriteria };
    }

    saveFeatureFlagAPIcall(payload);
  };

  const handleRedirect = (event) => {
    setLoader(true);
    navigate("/");
  };

  const saveFeatureFlagAPIcall = async (payload) => {
    try {
      await axios.post(serviceList("flags"), payload);

      setLoader(false);
      setAlertSpec({
        className: "alert alert-success",
        message: "The feature flag was successfully saved",
      });
      setAlert(true);
      timeOut();
    } catch (error) {
      setLoader(false);

      let message =
        error?.response?.data?.errors?.[0] ||
        "Something went wrong. Try again later";

      setAlertSpec({
        className: "alert alert-danger",
        message: message,
      });
      setAlert(true);
      timeOut();
    }
  };

  const onUsersListInput = (users, index) => {
    const list = configurationList;
    list[index].users = users;
    setConfigurationList([...list]);
  };

  const onProjectsListInput = (projects, index) => {
    const list = configurationList;
    list[index].projects = projects;
    setConfigurationList([...list]);
  };

  const handleDelete = (index) => {
    let list = configurationList;
    list.splice(index, 1);
    setConfigurationList([...list]);
  };

  const setReleaseStatusOptions = () => {
    if (/^RF/.test(featureName.value)) {
      return statusDropDownValues.filter((item) => item.value !== "evaluating");
    }
    return statusDropDownValues;
  };
  return (
    <>
      <div className="grid_list">
        <div className="grid_display">EnGig Flag Release</div>

        <div className="grid-form-wrapper">
          <div className="list-container top-level-section">
            <div className="grid-form-column">
              <label for="envSelect" className="form-label">
                Environment
              </label>
              <SelectField
                id="envSelect"
                options={envDropDownValues}
                placeholder="Select Environment"
                handleSelect={(environment) => setEnvironment(environment)}
                recievedValues={environment}
                disabled={true}
                classNamePrefix="bv-select-dropdown"
              />
            </div>
            <div className="grid-form-column">
              <label for="featureName" className="form-label">
                Feature
              </label>
              <InputField
                id="featureName"
                placeholder="Feature Name"
                handleInput={(feature) => setFeatureName(feature)}
                recievedValues={featureName}
                disabled={true}
              />
            </div>
            <div className="grid-form-column">
              <label for="status" className="form-label">
                Release Status
              </label>
              <SelectField
                id="status"
                options={setReleaseStatusOptions()}
                placeholder="Select Release Status"
                handleSelect={(status) => setReleaseStatus(status)}
                recievedValues={status}
                classNamePrefix="bv-select-dropdown"
              />
            </div>
          </div>
          {status?.value === "evaluating" && (
            <>
              {configurationList.map((item, index) => (
                <div className="list-container" key={"item-" + index}>
                  <div className="grid-form-column">
                    <MultiValueInputField
                      placeholder="Users"
                      onValueChange={(event) => onUsersListInput(event, index)}
                      recievedValues={item.users}
                      classNamePrefix="bv-form-multi-textfield-select"
                      className="bv-form-multi-textfield"
                      showLabel={index > 0 ? false : true}
                    />
                  </div>
                  <button
                    className={`icon-btn only-btn-view link-action-button${
                      index > 0 ? " no-label-section" : ""
                    }`}
                  >
                    <IconGenerator
                      classes="icon-svg icon-theme icon-size-24"
                      iconName="icon-link"
                    />
                  </button>
                  <div className="grid-form-column">
                    <MultiValueInputField
                      placeholder="Projects"
                      onValueChange={(event) =>
                        onProjectsListInput(event, index)
                      }
                      recievedValues={item.projects}
                      classNamePrefix="bv-form-multi-textfield-select"
                      className="bv-form-multi-textfield"
                      showLabel={index > 0 ? false : true}
                    />
                  </div>

                  <button
                    className={`icon-btn icon-btn--primary link-action-button${
                      index > 0 ? " no-label-section" : ""
                    }`}
                    onClick={() => handleDelete(index)}
                  >
                    <Tooltip
                      position="top"
                      tooltipClassName="theme-blue-dark"
                      parentTitle="true"
                      anchorElement="target"
                    >
                      <IconGenerator
                        classes="icon-svg icon-theme icon-size-16"
                        iconName="icon-trash"
                        key={"remove-" + index}
                        id={"remove-" + index}
                        iconTitle="Delete Row"
                      />
                    </Tooltip>
                  </button>
                </div>
              ))}
              <button
                className="btn-default-bv addRow"
                id={"add-more"}
                onClick={() =>
                  setConfigurationList([
                    ...configurationList,
                    { users: [], projects: [] },
                  ])
                }
              >
                <IconGenerator
                  classes="icon-svg icon-theme icon-size-16"
                  iconName="icon-plus-circle"
                />
                Add Row
              </button>
            </>
          )}
          <div className="grid-list--footer button-pack-group">
            <button
              className="btn btn-default-bv btn-outline-bv"
              onClick={handleRedirect}
              id="back-button"
            >
              Back
            </button>
            <button
              className="btn btn-primary-dark btn-primary"
              onClick={handleSave}
              disabled={!isInputValid}
              id="save-button"
            >
              Save
            </button>
          </div>
          {showAlert && (
            <Alerts
              className={alertSpec.className}
              message={alertSpec.message}
            />
          )}
        </div>
      </div>

      {showLoader && (
        <div className="global-loader">
          <Spinner animation="border" role="status" variant="primary">
            <span className="visually-hidden">Loading...</span>
          </Spinner>{" "}
        </div>
      )}
    </>
  );
}

export default FeatureFlagRelease;
