import { Modal, Button } from "react-bootstrap";
import { useState } from "react";
import IconGenerator from "../constants/IconPack/iconGenerator";
import "../designSystem/components/_modal.scss";

const ModalComponent = (props) => {
  const { handleSave, message, showModal, title, closeModal, showFooter } =
    props;

  return (
    <>
      <Modal show={showModal} onHide={closeModal} centered>
        <Modal.Header>
          <div className="bvModalHeader">
            <span className="bvModalTitle">{title}</span>
            <button
              className="icon-btn icon-btn--primary bvModalCloseButton"
              closeButton
              onClick={closeModal}
            >
              <IconGenerator
                classes="icon-svg icon-theme icon-size-16 "
                iconName="icon-close-circle"
              />
            </button>
          </div>
        </Modal.Header>
        <Modal.Body>{message}</Modal.Body>
        {showFooter ? (
          <Modal.Footer>
            <div className="button-pack-group">
              <button
                className="btn btn-default-bv btn-outline-bv"
                onClick={closeModal}
              >
                Cancel
              </button>
              <button
                className="btn btn-primary-dark btn-primary"
                onClick={handleSave}
              >
                Confirm
              </button>
            </div>
          </Modal.Footer>
        ) : null}
      </Modal>
    </>
  );
};

export default ModalComponent;
