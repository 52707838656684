import React, { useState, useEffect, useMemo } from "react";
import CreatableSelect from "react-select/creatable";

function MultiValueInputField(props) {
  const {
    placeholder,
    onValueChange,
    keyValue,
    recievedValues,
    classNamePrefix,
    className,
    showLabel,
  } = props;
  const [inputValue, setInputValue] = useState("");

  const createOption = (label) => ({
    label,
    value: label,
  });

  const values = useMemo(() => {
    const recievedItems = recievedValues.map((item) => {
      return createOption(item);
    });
    return recievedItems;
  }, [recievedValues]);

  const components = {
    DropdownIndicator: null,
  };

  const handleChange = (typedValue) => {
    let finalValue = typedValue.map((item) => item.value);
    onValueChange(finalValue);
  };

  const handleInputChange = (inputValue) => {
    setInputValue(inputValue);
  };

  const handleKeyDown = (event) => {
    if (!inputValue) return;
    if (event.key === "Enter" || event.key === "Tab") {
      setInputValue("");
      let finalValue = values.map((item) => item.value);
      onValueChange([...finalValue, inputValue]);

      event.preventDefault();
    }
  };

  return (
    <>
      {showLabel && <label className="form-label">{placeholder}</label>}

      <CreatableSelect
        components={components}
        inputValue={inputValue}
        isClearable={true}
        isMulti
        menuIsOpen={false}
        onChange={handleChange}
        onInputChange={(event) => {
          handleInputChange(event);
        }}
        onKeyDown={handleKeyDown}
        placeholder={placeholder}
        value={values}
        className={className}
        classNamePrefix={classNamePrefix}
      />
    </>
  );
}

MultiValueInputField.defaultProps = {
  placeholder: "Enter",
};

export default MultiValueInputField;
