import React, { lazy, Suspense } from "react";
import { Route, Routes as Switch, BrowserRouter } from "react-router-dom";
import FeatureListing from "./pages/featureListing/featureListing";
import FeatureFlagRelease from "./pages/featureFlagRelease/featureFlagRelease";

const Routes = (props) => {
  return (
    <>
      <Suspense fallback={<div>Loading...</div>}>
        <BrowserRouter>
          <Switch>
            <Route path="/" element={<FeatureListing />} />
            <Route
              exact
              path="/release-page"
              element={<FeatureFlagRelease />}
            />
          </Switch>
        </BrowserRouter>
      </Suspense>
    </>
  );
};

export default Routes;
