import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  env,
  releaseStatus,
  flagType,
} from "../../constants/featureAndReleaseFlag";
import { deleteReleaseFlag, serviceList } from "../../services/serviceList";
import { AxiosInstance as axios } from "../../services/interceptor";
import { Spinner, Button } from "react-bootstrap";
import ModalComponent from "../../sharedComponents/modal";
import { Tooltip } from "@progress/kendo-react-tooltip";
import "./featureListingStyle.scss";
import {
  Grid,
  GridColumn,
  GridNoRecords,
  GridColumnMenuFilter,
} from "@progress/kendo-react-grid";
import FeatureNameSearch from "../../components/featureNameSearch";
import ColumnMenuCheckboxFilter from "../../components/coloumnCheckboxFilter";
import Alerts from "../../sharedComponents/alertPopUps";
import "../../designSystem/components/_kendoGrid.scss";
import IconGenerator from "../../constants/IconPack/iconGenerator";
import { map } from "@progress/kendo-data-query/dist/npm/transducers";

const FeatureListing = (props) => {
  const navigate = useNavigate();
  const [response, setResponseData] = useState([]);
  const [evaluationCriteriaList, setEvaluationCriteria] = useState([]);
  const [evalColWidth, setEvalColWidth] = useState(400);
  const [showDeleteModal, setDeleteModal] = useState(false);
  const [showMoreModal, setShowMoreModal] = useState(false);
  const [deleteDataItemDetails, setDataItem] = useState({});
  const [showAlert, setAlert] = useState(false);
  const [alertSpec, setAlertSpec] = useState({});

  const [dataState, setDataState] = useState({
    skip: 0,
    take: 10,
    sort: [{ field: "feature", dir: "asc" }],
  });
  const [showLoader, setLoader] = useState(false);

  const timeOut = () => {
    setTimeout(() => setAlert(false), 5000);
  };

  useEffect(() => {
    handleStateChange({ dataState });
    setCriteriaColWidth();
  }, []);

  const filterParamSetUp = (filters) => {
    let filterObject = {};
    filters.forEach((columnToBeFiltered) => {
      let array = [];
      columnToBeFiltered.filters.forEach((filterValue) => {
        array.push(filterValue.value);
        filterObject[filterValue.field] = [...array];
      });
    });
    return filterObject;
  };

  const handleDeleteModal = (dataItem) => {
    setDataItem({ ...dataItem });
    setDeleteModal(true);
  };

  const handleDelete = async () => {
    const dataItem = deleteDataItemDetails;
    setDeleteModal(false);
    try {
      await axios.delete(deleteReleaseFlag("flags", dataItem?.id));
      handleStateChange({ dataState });
      let message = "Record deleted successfully";

      setAlertSpec({
        className: "alert alert-success",
        message: message,
      });
      setAlert(true);
      timeOut();
    } catch (err) {
      let message = "Something went wrong. Try again later";

      setAlertSpec({
        className: "alert alert-danger",
        message: message,
      });
      setAlert(true);
      timeOut();
    }
  };

  const handleStateChange = async (event) => {
    setLoader(true);
    const pageNumber = event.dataState.skip / event.dataState.take + 1;
    const limit = event.dataState.take;
    let params = { page: pageNumber, limit: limit };

    if (event.dataState.sort?.length > 0) {
      params.sortField = event.dataState.sort[0].field;
      params.sortOrder = event.dataState.sort[0].dir;
    }

    if (event.dataState.filter?.filters?.length > 0) {
      const filters = filterParamSetUp(event.dataState.filter.filters);
      const filtterKeys = Object.keys(filters);
      filtterKeys.forEach((key) => {
        params[key] = filters[key].join(",");
      });
    }

    setDataState(event.dataState);

    try {
      const response = await axios.get(serviceList("flags"), {
        params,
      });
      setResponseData(response);
      setLoader(false);
    } catch (err) {
      setLoader(false);

      let message = "Something went wrong. Try again later";

      setAlertSpec({
        className: "alert alert-danger",
        message: message,
      });
      setAlert(true);
      timeOut();
    }
  };

  const EvaluatingCriteriaCell = (props) => {
    const { dataItem } = props;
    let cell = <td />;
    if (dataItem.evaluationCriteria) {
      let totalUsers = 0;
      let totalProjects = 0;

      dataItem.evaluationCriteria.forEach((item) => {
        totalUsers += item?.users?.length;
        totalProjects += item?.projects?.length;
      });

      cell = (
        <td
          className="eval-criteria"
          key={dataItem.environment + "-" + dataItem.feature}
        >
          {
            <div className="criteria-wrapper">
              {totalUsers > 0 && (
                <span className="criteria-item">
                  <span className="criteria-title">Users </span>
                  <span className="criteria-count">+{totalUsers}</span>
                </span>
              )}
              {totalProjects > 0 && (
                <span className="criteria-item">
                  <span className="criteria-title">Projects </span>
                  <span className="criteria-count">+{totalProjects}</span>
                </span>
              )}
              <div className="criteria-item">
                <button
                  className="btn btn-plain"
                  onClick={() => {
                    setEvaluationCriteria(dataItem.evaluationCriteria);
                    setShowMoreModal(true);
                  }}
                >
                  View More
                </button>
              </div>
            </div>
          }
        </td>
      );
    }

    return cell;
  };

  const redirectToRealease = (dateItem) => {
    navigate("/release-page", {
      state: { environment: dateItem.environment, feature: dateItem.feature },
    });
  };

  const ActionCell = (props) => {
    const { dataItem } = props;
    return (
      <td {...props} locked="true">
        <Tooltip
          position="top"
          tooltipClassName="theme-blue-dark"
          parentTitle="true"
          anchorElement="target"
        >
          <button
            type="button"
            className="icon-btn icon-btn--primary"
            title="Edit"
            aria-label="edit"
            onClick={() => redirectToRealease(dataItem)}
          >
            <IconGenerator
              classes="icon-svg icon-theme icon-size-16"
              iconName="icon-edit"
              iconTitle="Edit"
            />
          </button>
          {dataItem.flagType === "release-flag" && (
            <button
              type="button"
              className="icon-btn icon-btn--primary"
              aria-label="delete row"
              onClick={() => handleDeleteModal(dataItem)}
              data-testId="row-delete-sideSection"
            >
              <IconGenerator
                classes="icon-svg icon-theme icon-size-14"
                iconName="icon-trash"
                iconTitle="Delete"
              />
            </button>
          )}
        </Tooltip>
      </td>
    );
  };
  const EnvironColIcon = (dataItem) => {
    switch (dataItem.environment.toLowerCase().trim()) {
      case "dev":
        return (
          <IconGenerator
            classes="icon-svg icon-theme icon-size-10 "
            iconName="icon-dev"
          />
        );
      case "sit":
        return (
          <IconGenerator
            classes="icon-svg icon-theme icon-size-10 "
            iconName="icon-sit"
          />
        );
      case "uat":
        return (
          <IconGenerator
            classes="icon-svg icon-theme icon-size-10 "
            iconName="icon-uat"
          />
        );
      default:
        break;
    }
  };
  const EnvironCol = (props) => {
    const { dataItem } = props;
    return (
      <td {...props} locked="true">
        <div className="env-col-wrapper">
          <button className="icon-btn icon-btn--primary ">
            <EnvironColIcon {...dataItem} />
          </button>
          <span className="col-environment-title">{dataItem.environment}</span>
        </div>
      </td>
    );
  };
  const CriteriaList = (props) => {
    return (
      <div className="engig-criteria">
        <div className="engig-criteria-title">Evaluating Criteria</div>
        <div className="engig-criteria-table">
          <table>
            <thead>
              <tr>
                <th>Users</th>
                <th className="item-projectName">Projects</th>
              </tr>
            </thead>
            <tbody>
              {evaluationCriteriaList.map((element, index) => (
                <tr>
                  <td>
                    <div className="item-wrapper">
                      {element?.users.map((userList) => (
                        <div className="item-list">{userList}</div>
                      ))}
                    </div>
                  </td>
                  <td className="item-projectName">
                    <div className="item-wrapper">
                      {element?.projects.map((projects) => (
                        <div className="item-list">{projects}</div>
                      ))}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  const gridWidths = ["195", "200", "165", "300", "0", "113"];
  const otherWidth = gridWidths.reduce((a, b) => Number(a) + Number(b), 0);

  const setCriteriaColWidth = (event) => {
    const minWidth = 400;
    let grid = window.innerWidth - 59;
    const colWidth = grid - otherWidth;

    setEvalColWidth(Math.max(colWidth, minWidth));

    return Math.max(colWidth, minWidth);
  };

  window.addEventListener("resize", setCriteriaColWidth);

  return (
    <>
      <div className="grid_list" key={setCriteriaColWidth}>
        <div className="grid_display">EnGig Features List</div>
        <div className="grid-absolute-wrapper">
          <div className="grid-absolute-content">
            <Grid
              data={response?.data?.data}
              sortable
              total={response?.data?.totalRecords}
              {...dataState}
              pageable={{ buttonCount: 3, pageSizes: [10, 20, 50, 100] }}
              onDataStateChange={(event) => {
                handleStateChange(event);
              }}
            >
              <GridNoRecords>
                {!showLoader ? "No records available" : " "}
              </GridNoRecords>
              <GridColumn
                field="environment"
                title="Environment"
                columnMenu={(props) => ColumnMenuCheckboxFilter(props, env)}
                sortable={false}
                cell={(props) => <EnvironCol {...props} />}
                width={gridWidths[0]}
                headerClassName={`box-shadow-sticky direction-right`}
                className="box-shadow-sticky direction-right"
                locked
              />
              <GridColumn
                field="status"
                title="Release Status"
                width={gridWidths[1]}
                columnMenu={(props) =>
                  ColumnMenuCheckboxFilter(props, releaseStatus)
                }
                className="capitalize"
                sortable={false}
              />
              <GridColumn
                field="flagType"
                width={gridWidths[2]}
                title="Flag Type"
                columnMenu={(props) =>
                  ColumnMenuCheckboxFilter(props, flagType)
                }
                className="capitalize"
                sortable={false}
              />

              <GridColumn
                field="feature"
                title="Feature Name"
                sortable
                width={gridWidths[3]}
                className="clickable"
                columnMenu={(props) => (
                  <GridColumnMenuFilter
                    expanded
                    {...props}
                    filterUI={FeatureNameSearch}
                  />
                )}
                headerClassName="enable-pointer"
              />
              <GridColumn
                field="evaluationCriteria"
                title="Evaluating Criteria"
                cell={EvaluatingCriteriaCell}
                sortable={false}
                width={evalColWidth}
              />
              <GridColumn
                field="action"
                width={gridWidths[5]}
                title="Actions"
                cell={(props) => <ActionCell {...props} />}
                sortable={false}
                locked
                className="box-shadow-sticky direction-left"
                headerClassName={`box-shadow-sticky direction-left`}
              />
            </Grid>
          </div>
        </div>
      </div>

      {showAlert && (
        <Alerts className={alertSpec.className} message={alertSpec.message} />
      )}

      {showLoader && (
        <div className="global-loader">
          <Spinner animation="border" role="status" variant="primary">
            <span className="visually-hidden">Loading...</span>
          </Spinner>{" "}
        </div>
      )}

      <ModalComponent
        showModal={showDeleteModal}
        closeModal={() => setDeleteModal(false)}
        handleSave={handleDelete}
        title="Confirmation"
        message="Are you sure you want to delete this record?"
        showFooter={true}
      />

      <ModalComponent
        showModal={showMoreModal}
        closeModal={() => setShowMoreModal(false)}
        // handleSave={handleDelete}
        title="EnGig Feature List - Evaluating Criteria List "
        message={<CriteriaList />}
        showFooter={false}
      />
    </>
  );
};

export default FeatureListing;
