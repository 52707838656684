import "./style.css";

const Alerts = (props) => {
  const { className, message } = props;

  return (
    <div
      className={"alert-message alert-dismissible fade show " + className}
      role="alert"
    >
      {message}
    </div>
  );
};

Alerts.defaultProps = {
  className: "alert alert-success",
  message: "Success",
};

export default Alerts;
