import axios from "axios";
import { InteractionRequiredAuthError } from "msal";
import {
  getItemFromSessionStorage,
  setItemToSessionStorage,
} from "../pages/loginSettings/login";
import {
  loginRequest,
  msalInstance,
  tokenRequestForGraphApi,
  LOGIN_TYPES,
} from "../constants/config/msalConfig";
import { getToken } from "../pages/loginSettings/login";
import { login } from "./serviceList.js";

const AxiosInstance = axios.create({
  timeout: 40000,
});

// timeout need to be optimized mail api taking more time for temp kept 40000

const acquireTokenSilentCall = async (response) => {
  // fetch loginDetails from API
  const payload = {
    userEmail: response.account.userName,
    accessToken: response.accessToken,
  };

  const res = await axios.post(login("adLogin"), payload);

  setItemToSessionStorage(
    "msalExpiry",
    JSON.stringify({
      adTokenExpiryTime: response.expiresOn,
    })
  );

  return res.data.token;
};

const handleReqAndResError = () => {
  localStorage.clear();
  sessionStorage.clear();
  msalInstance.clearCache();
  msalInstance.loginRedirect(loginRequest);
};

// request interceptor
AxiosInstance.interceptors.request.use(
  async (config) => {
    let isExpiriedSession = false;
    const loginData = JSON.parse(getItemFromSessionStorage("loginData"));
    let token = loginData?.token;
    const expiresOn = JSON.parse(getItemFromSessionStorage("msalExpiry"));
    if (expiresOn !== null) {
      isExpiriedSession =
        new Date() * 1000 > new Date(expiresOn.adTokenExpiryTime) * 1000;
    }
    if (isExpiriedSession) {
      // if (!getItemFromSessionStorage("loginData")) {
      // AD LOGIN
      try {
        const response = await msalInstance.acquireTokenSilent(
          tokenRequestForGraphApi
        );
        if (msalInstance.getAccount()) {
          token = await acquireTokenSilentCall(response);
        }
      } catch (err) {
        if (err.name === "InteractionRequiredAuthError") {
          const response = await msalInstance.acquireTokenPopup(loginRequest);
          token = await acquireTokenSilentCall(response);
        }
      }
      //}
    }
    if (token) {
      config.headers.Authorization = token;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

// response interceptor
AxiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    const responseStatus = error?.response?.status;
    if (
      responseStatus === 401 &&
      !originalRequest._retry &&
      error?.response?.data?.status != 407
    ) {
      originalRequest._retry = true;
      if (error instanceof InteractionRequiredAuthError) {
        try {
          getToken();
          const token = JSON.parse(
            getItemFromSessionStorage("loginData")
          ).token;
          originalRequest.headers.Authorization = token;
          return axios(originalRequest);
        } catch (err) {
          handleReqAndResError();
        }
      } else {
        const token = JSON.parse(getItemFromSessionStorage("loginData"))?.token;
        return axios
          .post(login("refreshToken"), {
            refreshToken: token,
          })
          .then(
            (response) => {
              const responseData = response?.data;
              if (responseData !== "") {
                // restore login response from localstorage for userdetails
                if (process.env.REACT_APP_USER_EMAIL_LOGIN === "true") {
                  setItemToSessionStorage(
                    "loginData",
                    JSON.stringify(responseData)
                  );
                }
              }
            },
            (error) => {
              handleReqAndResError();
            }
          );
      }
    }
    if (
      responseStatus === 401 &&
      originalRequest._retry &&
      error.response.data.status !== 407
    ) {
      handleReqAndResError();
    }
    return Promise.reject(error);
  }
);

export { AxiosInstance, handleReqAndResError };
