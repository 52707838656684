import { GridColumnMenuCheckboxFilter } from "@progress/kendo-react-grid";

const ColumnMenuCheckboxFilter = (props, checkBoxValues) => {
  return (
    <div>
      <GridColumnMenuCheckboxFilter
        {...props}
        data={checkBoxValues}
        expanded={true}
        data-testId="filter-column"
      />
    </div>
  );
};

export default ColumnMenuCheckboxFilter;
