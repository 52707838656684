import axios from "axios";
import {
  msalInstance,
  tokenRequestForGraphApi,
} from "../../constants/config/msalConfig";
import { login } from "../../services/serviceList";
const hasWindow = typeof window !== "undefined";

export function setItemToSessionStorage(key, value) {
  if (hasWindow && key) {
    sessionStorage.setItem(key, value);
  }
}
export function getItemFromSessionStorage(key) {
  if (hasWindow && key) {
    return sessionStorage.getItem(key);
  }
}

export function removeItemFromSessionStorage(key) {
  if (hasWindow && key) {
    sessionStorage.removeItem(key);
  }
}

export const getToken = async () => {
  return msalInstance
    .acquireTokenSilent(tokenRequestForGraphApi)
    .then((response) => {
      setItemToSessionStorage(
        "msalExpiry",
        JSON.stringify({
          adTokenExpiryTime: response.expiresOn,
        })
      );

      return sendToken(response).then((res) => {
        return res;
      });
    })
    .catch((err) => {
      if (err.name === "InteractionRequiredAuthError") {
        return msalInstance.acquireTokenRedirect(tokenRequestForGraphApi);
      }
    });
};

export const sendToken = async (response) => {
  if (msalInstance.getAccount()) {
    const payload = {
      userEmail: response.account.userName,
      accessToken: response.accessToken,
    };
    try {
      const response = await axios.post(login("adLogin"), payload);
      setItemToSessionStorage("loginData", JSON.stringify(response.data));
      return true;
    } catch (error) {
      return false;
    }
  }
};
